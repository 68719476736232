export default [
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'slot',
      name: 'currency',
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'In Wallets',
    },
    cell: {
      type: 'slot',
      name: 'isInUse',
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'In P2P',
    },
    cell: {
      type: 'slot',
      name: 'isP2PEnabled',
    },
  },
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Decimals',
    },
    cell: {
      type: 'slot',
      name: 'decimals',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Sort id',
      sort: 'sortid',
    },
    cell: {
      type: 'text',
      value: (e) => e.sortId,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'currencyType',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'P2P Fee',
    },
    cell: {
      type: 'slot',
      name: 'fees',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'additional',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Edit',
    },
    cell: {
      type: 'slot',
      name: 'editActions',
    },
  },
];
