var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status-modal"},[_c('v-dialog',{attrs:{"max-width":"480"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline mb-6"},[(_vm.isCreating)?[_vm._v(" "+_vm._s(_vm.$t('Create currency'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('Edit currency'))+" ")]],2),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('Name'),"placeholder":" "},model:{value:(_vm.input.name),callback:function ($$v) {_vm.$set(_vm.input, "name", $$v)},expression:"input.name"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 d-flex align-center"},[_c('v-switch',{attrs:{"inset":"","label":_vm.$t('Use in Wallet')},model:{value:(_vm.input.isInUse),callback:function ($$v) {_vm.$set(_vm.input, "isInUse", $$v)},expression:"input.isInUse"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('Includes currency for use in balances.'))+" ")])])],1),_c('v-col',{staticClass:"pt-0 pb-0 d-flex align-center"},[_c('v-switch',{attrs:{"inset":"","label":_vm.$t('Use in P2P')},model:{value:(_vm.input.isP2PEnabled),callback:function ($$v) {_vm.$set(_vm.input, "isP2PEnabled", $$v)},expression:"input.isP2PEnabled"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('Includes currency for P2P trading.'))+" ")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('Title'),"placeholder":" "},model:{value:(_vm.input.title),callback:function ($$v) {_vm.$set(_vm.input, "title", $$v)},expression:"input.title"}})],1),_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('Symbol'),"placeholder":" "},model:{value:(_vm.input.symbol),callback:function ($$v) {_vm.$set(_vm.input, "symbol", $$v)},expression:"input.symbol"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.constant.currencies.CURRENCY_TYPE_LIST,"item-text":function (e) { return _vm.$t(("const." + (e.caption))); },"item-value":function (e) { return e.id; },"dense":"","outlined":"","label":_vm.$t('Type'),"placeholder":" "},model:{value:(_vm.input.currencyType),callback:function ($$v) {_vm.$set(_vm.input, "currencyType", $$v)},expression:"input.currencyType"}})],1),_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('Decimals'),"placeholder":" "},model:{value:(_vm.input.decimals),callback:function ($$v) {_vm.$set(_vm.input, "decimals", $$v)},expression:"input.decimals"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":_vm.$t('Sort Id'),"placeholder":" "},model:{value:(_vm.input.sortId),callback:function ($$v) {_vm.$set(_vm.input, "sortId", $$v)},expression:"input.sortId"}},'v-text-field',attrs,false),on))]}}])},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('The larger the currency identifier, the higher it appears in the list.'))+" ")])])],1),_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('CMC Id'),"placeholder":" "},model:{value:(_vm.input.cmcId),callback:function ($$v) {_vm.$set(_vm.input, "cmcId", $$v)},expression:"input.cmcId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"})],1),_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","outlined":"","label":_vm.$t('Aliases'),"placeholder":" ","messages":_vm.$t('Example: Биткоин / Биткойн / Ишесщшт')},model:{value:(_vm.aliasesModel),callback:function ($$v) {_vm.aliasesModel=$$v},expression:"aliasesModel"}}),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 d-flex align-center"},[_c('v-switch',{attrs:{"inset":"","label":_vm.$t('Is Assets')},model:{value:(_vm.input.isAssets),callback:function ($$v) {_vm.$set(_vm.input, "isAssets", $$v)},expression:"input.isAssets"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('In this case, the currency will be displayed in the list of popular currencies in the block of the main page of the service'))+" ")])])],1),_c('v-col',{staticClass:"pt-0 pb-0 d-flex align-center"},[_c('v-switch',{attrs:{"inset":"","label":_vm.$t('Is Cheap Fee')},model:{value:(_vm.input.isCheapFee),callback:function ($$v) {_vm.$set(_vm.input, "isCheapFee", $$v)},expression:"input.isCheapFee"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('In this case, the currency will be displayed in the list of currencies with low commissions in the block of the main page of the service'))+" ")])])],1)],1),(!_vm.isCreating)?_c('v-file-input',{staticClass:"mt-6",attrs:{"accept":"image/*","dense":"","label":_vm.$t('Icon'),"outlined":""},model:{value:(_vm.icon),callback:function ($$v) {_vm.icon=$$v},expression:"icon"}}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between"},[(!_vm.isCreating)?_c('v-btn',{attrs:{"color":"success","outlined":"","disabled":_vm.isApplyDisabled},on:{"click":_vm.apply}},[_vm._v(" "+_vm._s(_vm.$t('Apply'))+" ")]):_c('v-btn',{attrs:{"color":"success","outlined":"","disabled":_vm.isApplyDisabled},on:{"click":_vm.apply}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }