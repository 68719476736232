//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Modal from './Modal.vue';
import ModalFee from './ModalFee.vue';

import tableConfig from './tableConfig';

export default {
  components: {
    Modal,
    ModalFee,
  },

  data() {
    return {
      tableConfig,
      loader: false,
      search: '',
      constant,
      editingCurrency: {
        modal: false,
        data: {},
        isCreating: false,
      },
      editingFee: {
        modal: false,
        data: {},
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('paymentsAndCurrenciesCurrencies', ['page', 'pages', 'data', 'sort']),

    preparedData() {
      const { data } = this;
      const search = this.search.toLowerCase();
      const filteredData = data.filter((e) => (
        e.name.toLowerCase().includes(search) || e.title.toLowerCase().includes(search)
      ));
      return filteredData;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('paymentsAndCurrenciesCurrencies', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      createCurrencyAction: 'createCurrency',
      editCurrencyAction: 'editCurrency',
      setCurrencyIconAction: 'setCurrencyIcon',
      deleteCurrencyIconAction: 'deleteCurrencyIcon',
      createFeeAction: 'createFee',
      editFeeAction: 'editFee',
    }),
    ...mapActions('currencies', {
      loadCommonCurrencies: 'getCurrencies',
    }),
    ...mapActions('paymentsAndCurrenciesPayments', {
      loadPaymentsCurrencies: 'loadData',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    editCurrency(row) {
      this.editingCurrency.modal = true;
      this.editingCurrency.data = { ...row };
      this.editingCurrency.isCreating = false;
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    createCurrency() {
      this.editingCurrency.modal = true;
      this.editingCurrency.data = {};
      this.editingCurrency.isCreating = true;
    },

    editFee(row) {
      this.editingFee.modal = true;
      this.editingFee.data = { ...row };
    },

    applyEdit({ input, icon }) {
      const { isCreating } = this.editingCurrency;
      this.confirmAction({
        title: isCreating
          ? 'Are you sure you want to create currency?'
          : 'Are you sure you want to edit currency?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            if (isCreating) {
              await this.createCurrencyAction(input);
            } else {
              await this.editCurrencyAction(input);
            }
            if (icon) {
              const formData = new FormData();
              formData.append('Icon', icon, icon.name);
              await this.setCurrencyIconAction({
                params: {
                  CurrencyId: input.currencyId,
                },
                data: formData,
              });
            }
            this.editingCurrency.modal = false;
            await this.loadDataAction({});
            await this.loadCommonCurrencies(); // reload currencies for other pages
            await this.loadPaymentsCurrencies(); // reload currencies for payments table
            if (isCreating) {
              this.setSuccessNotification('Currency created');
            } else {
              this.setSuccessNotification('Currency edited');
            }
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    applyEditFee({ input }) {
      const { data } = this.editingFee;
      this.confirmAction({
        title: data?.fees?.length === 0
          ? 'Are you sure you want to create P2P Fee?'
          : 'Are you sure you want to edit P2P Fee?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            if (data?.fees?.length === 0) {
              await this.createFeeAction(input);
            } else {
              await this.editFeeAction(input);
            }
            this.editingCurrency.modal = false;
            await this.loadDataAction({});
            await this.loadCommonCurrencies(); // reload currencies for other pages
            await this.loadPaymentsCurrencies(); // reload currencies for payments table
            if (data?.fees?.length === 0) {
              this.setSuccessNotification('P2P Fee created');
            } else {
              this.setSuccessNotification('P2P Fee edited');
            }
            this.editingFee.modal = false;
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    getIconUrl(row) {
      const icon = row.icon ?? row.icons?.[0];
      if (icon) return icon.includes('://') ? icon : `${constant.settings.EXCHANGE_URL}${icon}`;
      return null;
    },
  },
};
